























import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import ProductCard from '~/components/molecules/Product/ProductCard.vue';
import MediumProductCard from '~/components/molecules/Product/MediumProductCard/MediumProductCard.vue';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import ProductDimensionsInterface from '~/types/product/attribute/ProductDimensions';
import { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import type { ProductCategories } from '~/types/product/ProductCategories';
import type { ProductCardSize } from '~/constants/productCardSize';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';

export default defineComponent({
  name: 'ProductCardWrapper',
  components: {
    ProductCard,
    MediumProductCard,
    MiniProductCardTile: () => import(/* webpackChunkName: "MiniProductCardTile" */
      '~/components/molecules/Product/MiniProductCardTile/MiniProductCardTile.vue'),
    MiniProductCard: () => import(/* webpackChunkName: "MiniProductCard" */
      '~/components/molecules/Product/MiniProductCard/MiniProductCard.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    discountedPrice: {
      type: Number,
      default: null,
    },
    sku: {
      type: String,
      required: true,
    },
    reviewsCount: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: '',
    },
    tags: {
      type: Array as PropType<ProductTag[]>,
      default: () => {
        return [];
      },
    },
    brand: {
      type: String,
      default: '',
    },
    dimensions: {
      type: Object as PropType<ProductDimensionsInterface>,
      default: () => {
        return { hasDimensions: false };
      },
    },
    priceGuaranteeUrl: {
      type: String,
      required: true,
    },
    stockCount: {
      type: Number,
      required: true,
    },
    parcelDeliveryDate: {
      type: String,
      default: '',
    },
    freightDeliveryDate: {
      type: String,
      default: '',
    },
    isFreight: {
      type: Boolean,
      default: false,
    },
    keyFacts: {
      type: Array as PropType<String[]>,
      default: () => [],
    },
    releaseDate: {
      type: String,
      required: true,
    },
    energyRating: {
      type: Object as PropType<Maybe<EnergyRatingDetails>>,
      default: (): Maybe<EnergyRatingDetails> => null,
    },
    currency: {
      type: String,
      required: true,
    },
    categories: {
      type: [Object, String] as PropType<ProductCategories>,
      required: true,
    },
    productBoxTypeTag: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<ProductCardSize>,
      default: PRODUCT_CARD_SIZE.RESPONSIVE,
      validator: (value: ProductCardSize) => {
        return Object.values(PRODUCT_CARD_SIZE).includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('product-clicked');
    };

    const componentSizes = {
      [PRODUCT_CARD_SIZE.MINI]: 'MiniProductCard',
      [PRODUCT_CARD_SIZE.MEDIUM]: 'MediumProductCard',
      [PRODUCT_CARD_SIZE.TILE_MINI]: 'MiniProductCardTile',
    };
    const componentName = computed(() => componentSizes[props.size]);
    const isResponsive = computed(() => props.size === PRODUCT_CARD_SIZE.RESPONSIVE);

    return {
      onClick,
      componentName,
      PRODUCT_CARD_SIZE,
      isResponsive,
    };
  },
});
